<template>
  <sims-layout>
    <p>
      Please select a tab to the left.
    </p>
  </sims-layout>
</template>

<script>
import simsLayout from "./simsLayout.vue";
export default {
  components: {simsLayout}
}
</script>

<style>

</style>